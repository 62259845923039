import React, { useEffect, useContext, useRef } from "react";
// @ts-ignore
import Parallax from 'parallax-js'

import { LoadingPercent, PageHandlerContext } from "../../providers/Index";
import { gsap } from "gsap";

let tl2rev: any = null,
  tl3rev: any = null,
  type: any = 1,
  parallax: any = null,
  blurElement: any = { a: 0 }; //start the blur at 0 pixels
const Landing: React.FC = () => {
  const {loading} = useContext(LoadingPercent);
  const {setPageHandler} = useContext(PageHandlerContext);
  const ref: any = useRef(null);
  const hype: any = useRef(null);

  useEffect(() => {
    let tl = gsap.timeline();
    let tl2 = gsap.timeline();
    let tl3 = gsap.timeline();
    tl3rev = gsap.timeline({paused: true});
    tl2rev = gsap.timeline({paused: true});

    tl.fromTo(
      ".landing__title",
      {letterSpacing: "78px", opacity: 0},
      {duration: 2, ease: "easeOut", letterSpacing: "8px", opacity: 1 }
    );
    tl2.fromTo(
      ".landing__bg-textimg",
      {width: "70%", opacity: 0},
      {duration: 2, ease: "easeOut", width: "100%", opacity: 1}
    );
    tl2rev.fromTo(
      ".landing__bg-text",
      {width: "100%", opacity: 1, scale: 1},
      {duration: 2, ease: "none", width: "50%", opacity: 0, y: 0, scale: 0.8}
    );
    tl3.fromTo(
      ".grad",
      {
        backgroundImage:
          "linear-gradient(0deg, rgba(254,31,31,1) 100%, rgb(255 255 255 / 0%) 100%)",
      },
      {
        duration: 3,
        ease: "easeOut",
        backgroundImage:
          "linear-gradient(0deg, rgba(254,31,31,1) 0%, rgb(255 255 255 / 0%) 15%)",
      }
    );
    tl3rev.fromTo(
      ".grad",
      {
        backgroundImage:
          "linear-gradient(0deg, rgba(254,31,31,1) 0%, rgb(255 255 255 / 0%) 15%)",
      },
      {
        duration: 0.9,
        ease: "easeIn",
        backgroundImage:
          "linear-gradient(0deg, rgba(254,31,31,1) 100%, rgb(255 255 255 / 0%) 100%)",
      }
    );
  }, []);

  useEffect(() => {
    parallax = new Parallax(document.getElementById("landing__main"));

    return () => {
      parallax.disable();
    }
  }, [])

  function applyBlur() {
    gsap.set([".landing__title"], {
      webkitFilter: "blur(" + blurElement.a + "px)",
      filter: "blur(" + blurElement.a + "px)",
    });
  }

  const handleMotions = () => {
    if (type === 1 && loading === 100) {
      type = 0;
      tl3rev.restart();
      tl2rev.restart();
      gsap.to(".nl", {
        x: 0,
        duration: 1.4,
        delay: 1.2,
        opacity: 1,
        ease: "power3.inOut",
      });
      gsap.to(".nr > *", {
        x: 0,
        duration: 1.4,
        delay: 1.0,
        ease: "power3.inOut",
        stagger: 0.1,
        opacity: 1,
      });
      gsap.to(".landing__title", {
        y: -120,
        duration: 1.6,
        scale: 0.8,
        ease: "power2.inOut",
      });
      gsap.fromTo(
        ".landing__bg",
        {opacity: 0, scale: 1.3},
        {
          opacity: 1,
          duration: 2.2,
          ease: "power4.inOut",
          scale: 1,
          onComplete: () => {
            type = 2;
          },
        }
      );
      gsap.fromTo(
        ".roadMap__video-container",
        {opacity: 0, x: 100},
        {
          opacity: 1,
          x: 0,
          delay: 2.6,
          ease: "power4.out",
          duration: 1.5,
        }
      );
    } else if (type === 2) {
      type = 0;
      let tl4 = gsap.timeline();
      gsap.to(".landing__bg", {
        opacity: 0,
        duration: 1,
        scale: 1.4,
      });
      gsap.to(".roadMap__video-container", {
        opacity: 0,
        duration: 1,
      });
      tl4
        .to(".landing__title", {
          y: 0,
          duration: 1,
          ease: "easeIn",
        })
        .to(blurElement, {
          duration: 1,
          a: 20,
          onUpdate: applyBlur,
        });
      gsap.to(".landing__title", {
        // y: 0,
        delay: 1,
        duration: 1,
        scale: 1.5,
        ease: "easeIn",
        opacity: 0,
      });
      gsap.to(".landing__video", {
        display: "flex",
        duration: 1,
        delay: 2,
        opacity: 1,
        scale: 1,
        filter: "blur(0px)",
        onStart: () => {
          ref.current.play()
        }
      });
      gsap.to(".landing__video-clip", {
        duration: 1,
        delay: 2,
        scale: 1,
        filter: "blur(0px)",
      });
    }
  }

  const videoHandler = () => {
    gsap.to(".landing__video", {
      display: "block",
      duration: 1,
      opacity: 0,
      scale: 1.5,
      filter: "blur(10px)",
      onComplete: () => {
        setPageHandler("main page");
        window.scrollTo(0, 0)
        hype.current.classList.add('hide');
        // @ts-ignore
        document.getElementById("layout").classList.add('dynamic');
        // @ts-ignore
        document.getElementById("landing").classList.add('hide');
        // @ts-ignore
        document.querySelector(".roadMap__video-container").classList.add('hide');
        // @ts-ignore
        document.querySelector(".landing__video").style.visibility = "hidden"

        ref.current.pause()
      }
    });
  }


  return (
    <>
      <section
        className={"landing showSection"}
        id="landing"
        onClick={handleMotions}
      >
        <main className="landing__main">
          <h1 className="landing__title">NARCOSLAND</h1>
          <div className="landing__bg-text">
            <img
              src="images/narcos-text.png"
              className="landing__bg-textimg"
              alt="narcos-text"
            />
            <div className="grad"/>
          </div>
          <div className={`landing__loader ${loading === 100 ? "none" : ""}`}>
            <div className="landing__count-up">{loading} %</div>
            <div className="landing__gun-container">
              <span className="landing__gun-container--right"/>
              <div
                className="landing__gun-container--percent"
                style={{width: `${loading}%`}}
              >
                <span className="landing__gun-container--left"/>
              </div>
            </div>
          </div>
          <div className="landing__bg-container" id="landing__main">
            <img
              className="landing__bg"
              src="images/roadMap.png"
              alt="narcos-text"
              data-depth="0.3"
            />
          </div>
        </main>
      </section>
      <div className="landing__video">
        <video
          playsInline
          ref={ref}
          onEnded={videoHandler}
          className="landing__video-clip"
        >
          <source src={"/videos/hype.mp4"} type="video/mp4"/>
        </video>
        <div className="landing__video-btn container">
          <button onClick={videoHandler}>SKIP</button>
        </div>
      </div>
      <div
        className="roadMap__video-container"
        onClick={() => {
          gsap.fromTo(
            ".hype",
            {
              display: "flex",
              scale: 0.8,
              opacity: 0,
            },
            {
              display: "flex",
              scale: 1,
              opacity: 1,
              onComplete: () => {
                hype.current.currentTime = 0;
                hype.current.play();
              },
            }
          );
        }}
      >
        <img
          className="landing__bg"
          src="images/hype-thumbnail.png"
          alt="narcos-text"
        />
        <span className="roadMap__video-container--play"/>
      </div>
      <div className="hype">
        <video
          ref={hype}
          controls
          className="hype-clip"
        >
          <source src={"/videos/hype.mp4"} type="video/mp4"/>
        </video>
        <div className="hype-btn container">
          <button
            className="button-close"
            onClick={() => {
              hype.current.currentTime = 0;
              gsap.to(".hype", {display: "none", scale: 0.8, opacity: 0});
              hype.current.pause()
            }}
          />
        </div>
      </div>

    </>
  );
};

export default Landing;

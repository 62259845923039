import React, {useState} from "react";

import { Discord } from "../SVG/Discord";
import { Facebook } from "../SVG/Facebook";
import { Twitter } from "../SVG/Twitter";
import { DiscordRed } from "../SVG/Discord copy";
import { FacebookRed } from "../SVG/Facebook copy";
import { TwitterRed } from "../SVG/Twitter copy";
import Menu from "./Menu";
// import {PageHandlerContext} from "../../providers/Index";

// gsap.registerPlugin(ScrollTrigger);

const Header: React.FC = () => {
  const [open, setopen] = useState(false);


  return (
    <>
      {" "}
      <header className="header" id="header1">
        <div
          className={open ? "header__menu header__open" : "header__menu nl"}
          onClick={() => setopen(!open)}
        >
          <div />
          <div />
          {/* <div /> */}
        </div>

        <nav className="header__nav nr" id="structure-header">
          <a href="">
            <Discord />
          </a>
          <a href="">
            <Facebook />
          </a>
          <a href="">
            <Twitter />
          </a>
          <div className="header__nav-play">PLAY GAME</div>
        </nav>
      </header>
      <header className="header header-red" id="header2">
        <div
          className={open ? "header__menu2 header__open" : "header__menu2"}
          onClick={() => setopen(!open)}
        >
          <div />
          <div />
          {/* <div /> */}
        </div>

        <nav className="header__nav" id="structure-header">
          <a href="">
            <DiscordRed />
          </a>
          <a href="">
            <FacebookRed />
          </a>
          <a href="">
            <TwitterRed />
          </a>
          <div className="header__nav-play2">PLAY GAME</div>
        </nav>
      </header>
      <Menu open={open} setopen={setopen} />
    </>
  );
};

export default Header;

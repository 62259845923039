import SVG from "../SVG";

export const Facebook = () => {
  return (
    <SVG
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3327 9.98983C18.3327 5.38745 14.6017 1.65649 9.99936 1.65649C5.39697 1.65649 1.66602 5.38745 1.66602 9.98983C1.66602 14.1492 4.71339 17.5968 8.69727 18.2219V12.3987H6.58139V9.98983H8.69727V8.1539C8.69727 6.06535 9.94137 4.91171 11.8449 4.91171C12.7566 4.91171 13.7103 5.07446 13.7103 5.07446V7.12525H12.6595C11.6243 7.12525 11.3014 7.76762 11.3014 8.42664V9.98983H13.6126L13.2432 12.3987H11.3014V18.2219C15.2853 17.5968 18.3327 14.1492 18.3327 9.98983"
        fill="#F5ECE2"
      />
    </SVG>
  );
};

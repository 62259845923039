import React, {useEffect, useState} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const team = [
    { id: 0,name: "Michael McDonald", title: "Frontend Developer" },
    { id: 1,name: "Michael McDonald", title: "Frontend Developer" },
    { id: 2, name: "Michael McDonald", title: "Frontend Developer" },
    { id: 3, name: "Michael McDonald", title: "Frontend Developer" },
];

const Team: React.FC = () => {
  useEffect(() => {
      {team?.forEach((e) => {
          gsap.fromTo(
              `.imageL${e.id}`,
              {
                  position: "relative",
                  x: -100,
                  opacity: 0,
              },
              {
                  duration: 1.8,
                  ease: "power2.inOut",
                  position: "relative",
                  x: 0,
                  opacity: 1,
                  scrollTrigger: {
                      trigger: `.team${e.id}`,
                      start: "top center-=100",
                      end: "center center+=100",
                      // markers: true,
                      scrub: 1,
                      toggleActions: "restart none reverse reset",
                  },
              }
          );
          gsap.fromTo(
              `.imageR${e.id}`,
              {
                  position: "relative",
                  x: 100,
                  opacity: 0,
              },
              {
                  duration: 1.8,
                  ease: "power2.inOut",
                  position: "relative",
                  opacity: 1,
                  x: 0,
                  scrollTrigger: {
                      trigger: `.team${e.id}`,
                      start: "top center-=100",
                      end: "center center+=100",
                      // markers: true,
                      scrub: 1,
                      toggleActions: "restart none reverse reset",
                  },
              }
          );
          gsap.fromTo(
              `.infoL${e.id}`,
              {
                  position: "relative",
                  x: -100,
                  opacity: 0,
              },
              {
                  duration: 1.8,
                  ease: "power2.inOut",
                  position: "relative",
                  x: 0,
                  opacity: 1,
                  scrollTrigger: {
                      trigger: `.team${e.id}`,
                      start: "top center-=100",
                      end: "center center+=100",
                      // markers: true,
                      scrub: 1,
                      toggleActions: "restart none reverse reset",
                  },
              }
          );
          gsap.fromTo(
              `.infoR${e.id}`,
              {
                  position: "relative",
                  x: 100,
                  opacity: 0,
              },
              {
                  duration: 1.8,
                  ease: "power2.inOut",
                  position: "relative",
                  x: 0,
                  opacity: 1,
                  scrollTrigger: {
                      trigger: `.team${e.id}`,
                      start: "top center-=100",
                      end: "center center+=100",
                      // markers: true,
                      scrub: 1,
                      toggleActions: "restart none reverse reset",
                  },
              }
          );
      })

      }

  }, [])
  return (
    <div className="teamSection" id="Team">
      {team.map((item, i) => (
          <div className={["team",`team${i}` ].join(" ")} >
          <div className="team__avatar ">
            <div className="team__avatar--pattern">
              <img src="/images/michael.png" className={["team__avatar--img", i % 2 === 0 ? `imageL${i}` : `imageR${i}`].join(" ")} />
            </div>
          </div>
          <div className={["team__info", i % 2 === 0 ? `infoR${i}` : `infoL${i}`].join(" ")}>
            <div className="team__info--name">{item.name}</div>
            <div className="team__info--job">{item.title}</div>
            <div className="team__info--social-media">
              <span className="team__info--social-media__item box">
                <span className="top"></span>
                <span className="right"></span>
                <span className="bottom"></span>
                <span className="left"></span>
                <img src="/images/linkedin.png" />
              </span>
            </div>
          </div>
        </div>
        // </div>
      ))}
    </div>
  );
};

export default Team;

import React, {useEffect} from "react";
import { CounterBgDay } from "../components/SVG/CounterBgDay";
import CountdownTimer from "../components/CountDown/countDownTimer";
import {gsap} from "gsap";

const Counter: React.FC = () => {
  const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  const handleWheel = (e: any) => {
    const topOffset = window.scrollY;
    const section = document.getElementById('t3')
    // @ts-ignore
    const sectionTopOffset = section.offsetTop;
    // @ts-ignore
    const sectionHeight = section.offsetHeight;

    if (
      topOffset >= sectionTopOffset &&
      topOffset < sectionTopOffset + sectionHeight - 100
    ) {
      if (e.deltaY > 0) {
        gsap.to(window, {
          duration: 1.4,
          scrollTo: `#t4`
        })
      } else if (e.deltaY < 0) {
        gsap.to(window, {
          duration: 1.4,
          scrollTo: `#t2`
        })
      }
    }
  }

  useEffect(() => {
    gsap.fromTo(
        `.title`,
        {
          position: "relative",
          y: 60,
          opacity: 0
        },
        {
          duration: 1,
          ease: "power3.inOut",
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: `.t3`,
            toggleActions: "play none restart reset",
          },
        }
    );
    gsap.fromTo(
        `.counters`,
        {
          position: "relative",
          y: 80,
          opacity: 0
        },
        {
          duration: 1,
          delay: 0.2,
          ease: "power3.inOut",
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: `.t3`,
            toggleActions: "play none restart reset",
          },
        }
    );
    gsap.fromTo(
        `.counter__join`,
        {
          y: 80,
          opacity: 0
        },
        {
          duration: 0.9,
          delay: 0.4,
          ease: "power2.inOut",
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: `.t3`,
            toggleActions: "play none restart reset",
          },
        }
    );
  }, []);


  useEffect(() => {
    // document.addEventListener("touchmove", handleTouchMove, false);
    document.addEventListener("mousewheel", handleWheel, { passive: false });
    window.addEventListener('DOMMouseScroll', handleWheel, false);
  }, []);

  return (
    <section className="counter" id="Release-date">
      <div className="counter__main container">
        <div className="counter__title title">City awaits ...</div>
        <CountdownTimer targetDate={dateTimeAfterThreeDays} />
        <div className={"counter__join"}>
          Join our
          <a href="">{" Discord "}</a>
          to get the latest news and engage ine early mints.
        </div>
      </div>
    </section>
  );
};

export default Counter;

import React, {useEffect, useContext, useCallback} from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { PageHandlerContext } from "../providers/Index";

let offset: any = null,
    shot: any = null;

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
const Welcome: React.FC = () => {
  const { pageHandler } = useContext(PageHandlerContext);

  useEffect(() => {
    let body: any = document.querySelector("body");
    const start = () => {
      gsap.fromTo(
        ".welcome__main",
        {
          scale: 0.01,
          opacity: 0,
        },
        {
          duration: 0.8,
          ease: "easeIn",
          scale: 1,
          opacity: 1,
          onStart: () => {
            body.style.overflowY = "hidden";
          },
          onComplete: () => {
            body.style.overflowY = "scroll";
            scaleText();
          },
        }
      );
    };
    if (pageHandler === "main page") start();

    offset = window;
    shot = document.querySelector(".bullet__shot-first");
  }, [pageHandler]);

  const toggle = useCallback((className: string, hide: boolean = false) => {
    if(hide) {
      // @ts-ignore
      document?.querySelector(className)?.classList.add('hide')
    } else {
      // @ts-ignore
      document?.querySelector(className)?.classList.remove('hide')
    }
  }, [])

  const goToCharacters = useCallback(() => {
    gsap.to(window, {
      duration: 1.4,
      scrollTo: `#t2`
    })
  }, [])

  const scaleText = () => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".welcome__tween",
          toggleActions: "play reverse play reverse",
          start: "top top",
          end: "bottom bottom",
          pin: true,
          scrub: true,
          onEnter: toggle.bind(this, ".welcome__main", false),
        },
      })
      .to(".welcome__main", {
        scale: 2,
        duration: 0.5,
        opacity: 0,
        onComplete: toggle.bind(this, ".welcome__main", true),
        onStart: toggle.bind(this, ".welcome__main", false),
      })
      .fromTo(
        ".bullet__main",
        {
          scale: 0.4,
        },
        {
          scale: 1,
          duration: 0.5,
          autoAlpha: "1",
          onStart: toggle.bind(this, ".bullet__main", false),
          onReverseComplete: toggle.bind(this, ".welcome__main", false)
        }
      )
      .from(".bullet__shot-first", {
        display: "none",
        left: -offset.innerWidth / 2,
        opacity: 0.8,
        duration: 0.4,
      })
      .to(".bullet__shot-first", {
        ease: "none",
        left:
          offset.innerWidth < 700
            ? +offset.innerWidth
            : +offset.innerWidth / 1.2,
        duration: 0.6,
      })
      .to(
        ".bullet__fraction-first",
        {
          display: "block",
          left: -424,
          // transformOrigin: 'left',
          y: -300,
          rotateZ: 100 * 5,
          scale: 1,
          opacity: 0,
          duration: 0.6,
        },
        "< -0.02"
      )
      .to(
        ".bullet__fraction-second",
        {
          display: "block",
          left: -424,
          // transformOrigin: 'left',
          y: 300,
          rotateZ: 100 * 5,
          scale: 1,
          opacity: 0,
          duration: 0.6,
        },
        "< "
      )
      .to(
        ".bullet__narcosland-typo__fade",
        {
          rotateZ: offset.innerWidth < 800 ? 8 : 4.6,
          duration: 0.35,
          y: -48,
          ease: "none",
        },
        "< -0.01"
      )
      .to(
        ".bullet__narcosland-typo__fade",
        {
          duration: 0.9,
          y: -146,
          scale: 3,
          filter: "blur(2px)",
          ease: "none",
          autoAlpha: 0,
        },
        "< 0.35"
      )
      .to(
        ".bullet__narcosland-typo__main",
        {
          rotateZ: -2,
          y: 20,
          duration: 0.2,
          ease: "none",
        },
        "< -0.34"
      )
      .to(
        ".bullet__narcosland-typo__main",
        {
          rotateZ: -2,
          duration: 1.5,
          y: 100,
          filter: "blur(2px)",
          autoAlpha: 0,
          scale: 2.5,
        },
        "< 0.19"
      )
      .to(
        ".bullet__title-1",
        {
          rotateZ: 28,
          duration: 0.8,
          top: -46,
          left: -20,
        },
        "< 0.1"
      )
      .to(
        ".bullet__title-2",
        {
          rotateZ: -7,
          duration: 0.8,
          top: -33,
          left: 20,
        },
        "<"
      )
      .to(
        ".bullet__title-3",
        {
          rotateZ: -7,
          duration: 0.8,
          top: -33,
          left: 40,
        },
        "<"
      )
      .to(
        ".bullet__title-1",
        {
          duration: 2.4,
          y: "-50vh",
          x: "-35vw",
          scale: 3,
          filter: "blur(8px)",
          autoAlpha: 0,
        },
        "<.02"
      )
      .to(
        ".bullet__title-2",
        {
          duration: 2.4,
          y: "-50vh",
          x: "40vw",
          scale: 3,
          filter: "blur(8px)",
          autoAlpha: 0,
        },
        "<0.04"
      )
      .to(
        ".bullet__title-3",
        {
          duration: 2.4,
          y: "-50vh",
          x: "55vw",
          scale: 3,
          filter: "blur(8px)",
          autoAlpha: 0,
        },
        "<"
      )
      .fromTo(
        ".bullet__fraction-third",
        {
          display: "none",
          left: shot.style.left,
          opacity: 0.6,
        },
        {
          display: "block",
          duration: 0.1,
          left: shot.style.left,
          opacity: 1,
          scale: 1,
        },
        "<"
      )
      .fromTo(
        ".bullet__fraction-fourth",
        {
          left: shot.style.left,
          display: "none",
          opacity: 0.6,
        },
        {
          duration: 0.1,
          display: "block",
          left: shot.style.left,
          opacity: 1,
          scale: 1,
        },
        "<"
      )
      .to(
        ".bullet__fraction-third",
        {
          opacity: 0,
          ease: "none",
          duration: 0.2,
        },
        "< 0.3"
      )
      .to(
        ".bullet__fraction-fourth",
        {
          opacity: 0,
          ease: "none",
          duration: 0.2,
        },
        "<"
      )
      .to(
        ".bullet__shot-second",
        {
          left: offset.innerWidth + 345,
          duration: 0.4,
        },
        "< -0.6"
      )
      .to(
        ".bullet__shot-third",
        {
          left: offset.innerWidth + 345,
          duration: 0.4,
        },
        "< 0.2"
      )
      .to(".bullet__main", {
        scale: 2,
        duration: 0.5,
        opacity: 0,
        onComplete: toggle.bind(this, ".bullet__main", true),
        onStart: toggle.bind(this, ".bullet__main", false),
      })
      .fromTo(
        ".government__title",
        {
          scale: 0.4,
          opacity: 0,
        },
        {
          scale: 1,
          duration: 0.5,
          opacity: 1,
          onReverseComplete: toggle.bind(this, ".bullet__main", false),
        }
      )
      .to(".government__cars-middle", {
        display: "block",
        duration: 1,
        delay: 0.2,
        filter: "blur(0px)",
        scale: 0.5,
        left: offset.innerWidth < 991 ? "50%" : "48.5%",
        y: offset.innerWidth < 991 ? "-44%" : "-38%",
        opacity: 1,
      })
      .to(
        ".government__cars-left",
        {
          display: "block",
          duration: 0.5,
          left: offset.innerWidth < 991 ? "-100" : "-190px",
          y: "-100vh",
        },
        "< 0.2"
      )
      .to(
        ".government__cars-left",
        {
          display: "block",
          duration: 0.8,
          opacity: 0,
          scale: 0.1,
          filter: "blur(15px)",
        },
        "< 0.2"
      )
      .to(".government__title-left-text", {
        rotate: "8.5",
        x: "-100%",
        y: "-60%",
        transform: "matrix(0.94, 0.14, -0.4, 0.99, 0, 0)",
        filter: "blur(5px)",
        autoAlpha: 0,
        duration: 0.6,
      })
      .to(
        ".government__title-fraction1",
        {
          rotate: 80 * 2,
          scale: 0.5,
          opacity: 0,
          right: "400px",
          top: "-250px",
          duration: 0.6,
        },
        "<"
      )
      .to(
        ".government__title-right-text",
        {
          rotate: "-6",
          x: "100%",
          y: "-60%",
          transform: "matrix(0.94, -0.1, 0.4, 0.99, 0, 0)",
          filter: "blur(5px)",
          autoAlpha: 0,
          duration: 0.6,
        },
        "<"
      )
      .to(
        ".government__title-fraction2",
        {
          rotate: 95 * 3,
          scale: 0.8,
          opacity: 0,
          left: "300px",
          top: "-350px",
          duration: 0.6,
        },
        "<"
      )
      .to(
        ".government__cars-middle",
        {
          duration: 1,
          filter: "blur(10px)",
          scale: 0.3,
          left: "48.5%",
          y: "-58%",
          opacity: 0,
        },
        "<-0.02"
      )
      .to(
        ".government__cars-right",
        {
          display: "block",
          duration: 0.5,
          right: offset.innerWidth < 991 ? "-100px" : "-190px",
          y: "-100vh",
        },
        "< -0.2"
      )
      .to(
        ".government__cars-right",
        {
          duration: 0.8,
          opacity: 0,
          scale: 0.1,
          filter: "blur(15px)",
        },
        "< 0.2"
      )
      .to(
        ".government__main",
        {
          scale: 2,
          duration: 0.5,
          opacity: 0,
          onComplete: toggle.bind(this, ".government__main", true),
          onStart: toggle.bind(this, ".government__main", false),
        },
        "< 2"
      )
      .fromTo(
        ".stop__main",
        {
          scale: 0.4,
        },
        {
          scale: 1,
          duration: 0.5,
          autoAlpha: "1",
          onReverseComplete: toggle.bind(this, ".government__main", false),
        }
      )
      .from(
        ".stop__title-bullet1",

        {
          duration: 0.5,
          autoAlpha: "0",
          y: offset.innerHeight / 2,
          x: offset.innerWidth / 2,
        }
      )
      .from(
        ".stop__title-bullet3",

        {
          duration: 0.5,
          autoAlpha: "0",
          y: -offset.innerHeight / 2,
          x: -offset.innerWidth / 2,
        },
        "<"
      )
      .from(
        ".stop__title-bullet2",

        {
          duration: 0.5,
          autoAlpha: "0",
          y: offset.innerHeight / 2,
          x: -offset.innerWidth / 2,
        },
        "<"
      )
      .to(
        ".stop__title-break1",

        {
          scale: 1,
          duration: 0.1,
          ease: "none",
          opacity: 1,
        }
      )
      .to(
        ".stop__title-break2",

        {
          scale: 1,
          duration: 0.1,
          ease: "none",
          opacity: 1,
        },
        "<"
      )
      .to(
        ".stop__title-break3",

        {
          scale: 1,
          duration: 0.1,
          ease: "none",
          opacity: 1,
        },
        "<"
      )
      .to(
        ".stop__title-bullet1",

        {
          scale: 0.6,
          display: "none",
        },
        "< -0.5"
      )
      .to(
        ".stop__title-bullet2",

        {
          scale: 0.6,
          display: "none",
        },
        "< "
      )
      .to(
        ".stop__title-bullet3",
        {
          scale: 0.6,
          display: "none",
        },
        "< "
      )
      .add(goToCharacters)
  };

  return (
    <div className="welcome__tween">
      <section className="welcome" id="Narcosland">
        {/* <div className="welcome__scroll"> */}
        <div className="welcome__main absolute-center">
          <div className="welcome__title">welcome to</div>
          <div className="welcome__narcosland-typo">NARCOSLAND</div>
        </div>
        <img
          className="bullet__shot-second"
          src="./images/shot3.png"
          alt="bullshit"
        />
        <img
          className="bullet__shot-third"
          src="./images/shot2.png"
          alt="bullshit"
        />
        <div className="bullet__main flex absolute-center">
          <div className="bullet__title">
            <span className="bullet__title-1">You </span>
            <span className="bullet__title-2"> can't do </span>
            <span className="bullet__title-3"> shit</span>
          </div>
          <div className="bullet__narcosland-typo ">
            <img
              className="bullet__shot-first"
              src="./images/shot1.png"
              alt="bullet"
            />
            <img
              className="bullet__fraction-first"
              src="./images/fraction1.png"
              alt="fraction"
            />
            <img
              className="bullet__fraction-second"
              src="./images/fraction2.png"
              alt="fraction"
            />
            <img
              className="bullet__fraction-third"
              src="./images/fraction5.png"
              alt="fraction"
            />
            <img
              className="bullet__fraction-fourth"
              src="./images/fraction6.png"
              alt="fraction"
            />

            <div className="bullet__narcosland-typo__fade">
              WITHOUT MY PERMISSION
            </div>
            <div className="bullet__narcosland-typo__main">
              WITHOUT MY PERMISSION
            </div>
          </div>
        </div>
        <div className="government__main absolute-center">
          <div className="government__title">
            <div className="government__title-left">
              <span className="government__title-left-text">THE F*CKING</span>
              <img
                src="./images/fraction-car1.svg"
                alt=""
                className="government__title-fraction1"
              />
            </div>
            <div className="government__title-right">
              <span className="government__title-right-text">
                GOVERNMENT ...
              </span>

              <img
                src="./images/fraction-car2.svg"
                alt=""
                className="government__title-fraction2"
              />
            </div>
          </div>

          <div className="government__cars">
            <img
              src="./images/car.svg"
              alt="car-right"
              className="government__cars-right"
            />
            <img
              src="./images/car.svg"
              alt="car-middle"
              className="government__cars-middle"
            />
            <img
              src="./images/car.svg"
              alt="car-left"
              className="government__cars-left"
            />
          </div>
        </div>
        <div className="stop__main flex absolute-center">
          <div className="stop__title">
            <span className="stop__title-typo">
              Could not stop me
              <img
                src="./images/stop-bullet-1.png"
                alt="stop-bullet-1"
                className="stop__title-bullet1"
              />
              <img
                src="./images/stop-break-1.png"
                alt="stop-break-1"
                className="stop__title-break1"
              />
              <img
                src="./images/stop-bullet-3.png"
                alt="stop-bullet-3"
                className="stop__title-bullet3"
              />
              <img
                src="./images/stop-break-3.png"
                alt="stop-break-3"
                className="stop__title-break3"
              />
              <img
                src="./images/stop-bullet-2.png"
                alt="stop-bullet-2"
                className="stop__title-bullet2"
              />
              <img
                src="./images/stop-break-2.png"
                alt="stop-break-2"
                className="stop__title-break2"
              />
              {/* <img src="./images/stop-bullet-3.png" alt="stop-bullet-3" className="stop__title-bullet3" /> */}
            </span>
          </div>
        </div>
        {/* </div> */}
      </section>
    </div>
  );
};

export default Welcome;

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef ,useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import * as THREE from "three";

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('./models/elchapo.glb')
  const { actions } = useAnimations(animations, group)

  useEffect(() => {
    let animate = actions['Armature|mixamo.com|Layer0']
    animate.loop = THREE.LoopOnce;
    animate.clampWhenFinished = true;
    props.setAnimate(animate)
  }, [])
  
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <primitive object={nodes.mixamorigHips} />
          <group name="body">
            <skinnedMesh castShadow name="bodymesh" geometry={nodes.bodymesh.geometry} material={materials['body_MAT.001']} skeleton={nodes.bodymesh.skeleton} />
            <skinnedMesh castShadow name="bodymesh_1" geometry={nodes.bodymesh_1.geometry} material={materials['eyes_MAT.001']} skeleton={nodes.bodymesh_1.skeleton} />
          </group>
          <skinnedMesh castShadow name="cloth" geometry={nodes.cloth.geometry} material={materials['cloth_MAT.001']} skeleton={nodes.cloth.skeleton} />
          <skinnedMesh castShadow name="hair" geometry={nodes.hair.geometry} material={materials['hair_MAT.001']} skeleton={nodes.hair.skeleton} />
          <skinnedMesh castShadow name="tooth" geometry={nodes.tooth.geometry} material={nodes.tooth.material} skeleton={nodes.tooth.skeleton} />
          <skinnedMesh castShadow name="trousers" geometry={nodes.trousers.geometry} material={materials['pants_MAT.001']} skeleton={nodes.trousers.skeleton} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/elchapo.glb')

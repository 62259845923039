import React, { useContext } from "react";
import { PageHandlerContext } from "../../providers/Index";
import Header from "../Header";
import Slider from "../scrollSlider/Slider";
import Patterns from "../Patterns";

interface Props {
  children: React.ReactNode;
}
const Layout: React.FC<Props> = ({ children }) => {
  const PageHandlerData = useContext(PageHandlerContext);

  return (
    <>
      <Patterns/>
      <Header />
      <div className="layout__main" id="layout">
        {children}
      </div>
      {!(
        PageHandlerData.pageHandler === "0" || PageHandlerData.pageHandler === "1"
      ) && <Slider />}
    </>
  );
};

export default Layout;

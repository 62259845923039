import SVG from "../SVG";

export const DiscordRed = () => {
  return (
    <SVG
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7843 4.6026C14.7054 4.09783 13.5518 3.73098 12.3458 3.52209C12.1977 3.78988 12.0247 4.15006 11.9053 4.43658C10.6233 4.24377 9.35306 4.24377 8.0946 4.43658C7.97532 4.15006 7.79835 3.78988 7.64891 3.52209C6.44158 3.73098 5.28668 4.09918 4.20782 4.60528C2.03174 7.89361 1.44184 11.1003 1.73679 14.2614C3.18008 15.3392 4.5788 15.9939 5.95392 16.4224C6.29344 15.9551 6.59625 15.4584 6.85711 14.9349C6.36029 14.7461 5.88444 14.5131 5.43482 14.2427C5.5541 14.1543 5.67078 14.0619 5.7835 13.9668C8.52587 15.2495 11.5055 15.2495 14.2151 13.9668C14.3292 14.0619 14.4458 14.1543 14.5638 14.2427C14.1129 14.5144 13.6357 14.7474 13.1389 14.9362C13.3998 15.4584 13.7013 15.9565 14.0421 16.4237C15.4185 15.9953 16.8186 15.3406 18.2618 14.2614C18.6079 10.5968 17.6706 7.41964 15.7843 4.6026ZM7.23072 12.3173C6.40749 12.3173 5.73237 11.5488 5.73237 10.6129C5.73237 9.67702 6.39307 8.90716 7.23072 8.90716C8.06839 8.90716 8.74349 9.67567 8.72907 10.6129C8.73037 11.5488 8.06839 12.3173 7.23072 12.3173ZM12.7679 12.3173C11.9447 12.3173 11.2696 11.5488 11.2696 10.6129C11.2696 9.67702 11.9302 8.90716 12.7679 8.90716C13.6056 8.90716 14.2807 9.67567 14.2663 10.6129C14.2663 11.5488 13.6056 12.3173 12.7679 12.3173Z"
        fill="#FE1F1F"
      />
    </SVG>
  );
};

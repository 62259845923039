import React, { useRef, useEffect, useContext } from "react";
import Layout from "./components/Layout";
import Welcome from "./pages/Welcome";
import Landing from "./components/Landing";
import Three from "./pages/Three";
import { PageHandlerContext } from "./providers/Index";
import Counter from "./pages/Counter";
import Team from "./components/Header/Team";
import Contact from "./components/Header/Contact";
import { Partner } from "./pages/Partner";
import About from "./pages/About/About";
import {ScrollTrigger} from "gsap/ScrollTrigger";

function App() {
  const { setPageHandler } = useContext(PageHandlerContext);

  const showTooltip = (e: any) => {
    e.stopPropagation();
  };
  useEffect(() => {
    setPageHandler("landing")
    window.addEventListener("scroll", (e) => {
      showTooltip(e);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {

    ScrollTrigger.create({
      trigger: ".change-header",
      start: "-100px top",
      end: "bottom top",
      toggleClass: {
        targets: "#header2",
        className: "header2",
      },
      // markers: true,
    });
  }, [])

  const myContainer = useRef(null);

  return (
    <Layout>
      <Landing />
      <div className="t1 welcome__parent" id="#t1">
        <Welcome />
      </div>
      <div className="change-header">
        <div
          className="t2"
          ref={myContainer}
          id="t2"
          style={{ height: "100vh" }}
        >
          <Three />
        </div>
        <div className="t3" id='t3'>
          <Counter />
        </div>
        <div className="t4" id='t4'>
          <Team />
        </div>
        <div className="t5" id='t5'>
          <About />
        </div>
        <div className="t6" id='t6'>
          <Partner />
        </div>
      </div>
      <div className="t7" id='t7' style={{ height: "100vh" }}>
        <Contact />
      </div>
    </Layout>
  );
}

export default App;

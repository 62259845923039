import React from "react";

const Patterns: React.FC = () => {
  return (
    <div className="patterns__wrapper">
      <img src="/images/pattern-left.svg" alt="patternLeft" className="patterns__left"/>
      <img src="/images/pattern-right.svg" alt="patternRight" className="patterns__right"/>
    </div>
  );
};

export default Patterns;

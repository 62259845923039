import SVG from "../SVG";

export const TheFrame = () => {
  return (
    <SVG
      width="143"
      height="143"
      viewBox="0 0 143 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M139.854 94.3796H46.9043V1.34375H76.1335C93.0332 1.34375 109.241 8.05717 121.191 20.0071C133.141 31.957 139.854 48.1646 139.854 65.0646V94.3796Z"
        strokeWidth="0.688"
        strokeMiterlimit="10"
      />
      <path
        d="M132.304 98.899H42.5V9.06641H71.5004C79.4838 9.06267 87.39 10.6319 94.7668 13.6844C102.144 16.737 108.847 21.213 114.493 26.8569C120.14 32.5006 124.619 39.2019 127.675 46.5773C130.731 53.9526 132.304 61.858 132.304 69.8414V98.899Z"
        strokeWidth="0.688"
        strokeMiterlimit="10"
      />
      <path
        d="M124.754 103.476H38.0957V16.7891H66.9245C74.5187 16.7891 82.0387 18.2848 89.0549 21.191C96.071 24.0972 102.446 28.3569 107.816 33.7268C113.186 39.0967 117.446 45.472 120.352 52.4881C123.258 59.5043 124.754 67.024 124.754 74.6182V103.476Z"
        strokeWidth="0.704"
        strokeMiterlimit="10"
      />
      <path
        d="M117.261 108.05H33.6914V24.5098H62.2914C69.5075 24.5023 76.654 25.9171 83.323 28.6732C89.9919 31.4296 96.052 35.4731 101.157 40.573C106.262 45.6727 110.312 51.7288 113.075 58.3946C115.838 65.0606 117.261 72.2058 117.261 79.4218V108.05Z"
        strokeWidth="0.72"
        strokeMiterlimit="10"
      />
      <path
        d="M109.682 112.627H29.2871V32.2324H57.7155C71.4956 32.2401 84.7088 37.7176 94.4528 47.4614C104.197 57.2054 109.674 70.4186 109.682 84.1986V112.627Z"
        strokeWidth="0.736"
        strokeMiterlimit="10"
      />
      <path
        d="M102.131 117.259H24.8828V40.0391H53.111C66.0971 40.0391 78.5527 45.1919 87.7433 54.3665C96.9339 63.5411 102.109 75.9876 102.131 88.9737V117.259Z"
        strokeWidth="0.736"
        strokeMiterlimit="10"
      />
      <path
        d="M94.5793 121.807H20.4766V47.6758H48.6191C60.8215 47.7138 72.5112 52.5875 81.1264 61.2296C89.7416 69.8717 94.5793 81.5765 94.5793 93.779V121.807Z"
        strokeWidth="0.752"
        strokeMiterlimit="10"
      />
      <path
        d="M87.0575 126.384H16.0723V55.3984H43.9001C55.3438 55.4062 66.3168 59.9556 74.4086 68.0474C82.5006 76.1394 87.0498 87.1121 87.0575 98.5558V126.384Z"
        strokeWidth="0.768"
        strokeMiterlimit="10"
      />
      <path
        d="M79.5071 130.96H11.668V63.1211H39.2955C49.963 63.1285 60.191 67.3716 67.7311 74.9172C75.2715 82.463 79.5071 92.6938 79.5071 103.361V130.96Z"
        strokeWidth="0.784"
        strokeMiterlimit="10"
      />
      <path
        d="M71.9569 135.564H7.26367V70.8418H34.6911C39.5737 70.8418 44.4083 71.8036 48.9193 73.6721C53.4301 75.5405 57.5288 78.2792 60.9814 81.7315C64.4337 85.1841 67.1724 89.2828 69.0409 93.7936C70.9093 98.3047 71.8711 103.139 71.8711 108.022L71.9569 135.564Z"
        strokeWidth="0.784"
        strokeMiterlimit="10"
      />
      <path
        d="M64.4352 140.14H2.85938V78.5645H30.0866C39.1888 78.5645 47.9181 82.1804 54.3545 88.6165C60.7907 95.0526 64.4066 103.782 64.4066 112.884L64.4352 140.14Z"
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
    </SVG>
  );
};

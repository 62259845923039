import React, {useEffect} from "react";
import Button from "../../components/Button/Button";
import Frame from "./components/Frame";
import { FrameType } from "./types";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const FRAMES: FrameType[] = Array(5)
  .fill("LO CAPITAL")
  .map((title, index) => ({ id: index + 1, title }));

// gsap.registerPlugin(ScrollTrigger);

const Partner = () => {

    useEffect(() => {
        gsap.fromTo(
            `.button`,
            {
                position: "relative",
                bottom: "-100px",
                opacity: 0,
            },
            {
                duration: 1,
                ease: "none",
                position: "relative",
                bottom: "0px",
                opacity: 1,
                scrollTrigger: {
                    trigger: `.t6`,
                    start: "top center-=100",
                    end: "center center+=100",
                    // markers: true,
                    scrub: 1,
                    toggleActions: "restart none reverse reset",
                },
            }
        );
        gsap.fromTo(
            `.boxes`,
            {
                position: "relative",
                bottom: "-100px",
                left: "-100px",
                opacity: 0,
                scale: 0.1,
            },
            {
                duration: 1,
                scale: 1,
                y: 40,
                bottom: "0px",
                left: "0px",
                opacity: 1,
                ease: "power1.inOut",
                scrollTrigger: {
                    trigger: `.t6`,
                    start: "top center-=100",
                    end: "center center+=100",
                    // markers: true,
                    scrub: 1,
                    toggleActions: "restart none reverse reset",
                },
            }
        );
    }, [])

  return (
    <section className="partner" id="Partners">
      <div className="partner__main container">
        <div className="partner__frame__container">
          {FRAMES.map((frame) => (
            <Frame frame={frame} />
          ))}
        </div>
        <div className="partner__button__section button">
          <Button title="Become a Partner" />
        </div>
      </div>
    </section>
  );
};

export default Partner;
